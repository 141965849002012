// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CfCustomFeature112 from "../../blocks/CfCustomFeature112/src/CfCustomFeature112";
import CfCustomFeature2 from "../../blocks/CfCustomFeature2/src/CfCustomFeature2";
import CfCustomFeature4 from "../../blocks/CfCustomFeature4/src/CfCustomFeature4";
import CfCustomFeature10 from "../../blocks/CfCustomFeature10/src/CfCustomFeature10";
import CfCustomFeature17 from "../../blocks/CfCustomFeature17/src/CfCustomFeature17";
import CfCustomFeature6 from "../../blocks/CfCustomFeature6/src/CfCustomFeature6";
import CfCustomFeature15 from "../../blocks/CfCustomFeature15/src/CfCustomFeature15";
import CfCustomFeature13 from "../../blocks/CfCustomFeature13/src/CfCustomFeature13";
import CfCustomFeature3 from "../../blocks/CfCustomFeature3/src/CfCustomFeature3";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import CfCustomFeature8 from "../../blocks/CfCustomFeature8/src/CfCustomFeature8";
import CfCustomFeature9 from "../../blocks/CfCustomFeature9/src/CfCustomFeature9";
import CfCustomFeature1 from "../../blocks/CfCustomFeature1/src/CfCustomFeature1";
import CfCustomFeature7 from "../../blocks/CfCustomFeature7/src/CfCustomFeature7";
import CfCustomFeature12 from "../../blocks/CfCustomFeature12/src/CfCustomFeature12";
import CfCustomFeature5 from "../../blocks/CfCustomFeature5/src/CfCustomFeature5";
import CfCustomFeature16 from "../../blocks/CfCustomFeature16/src/CfCustomFeature16";
import CfCustomFeature14 from "../../blocks/CfCustomFeature14/src/CfCustomFeature14";



const routeMap = {
CfCustomFeature112:{
 component:CfCustomFeature112,
path:"/CfCustomFeature112"},
CfCustomFeature2:{
 component:CfCustomFeature2,
path:"/CfCustomFeature2"},
CfCustomFeature4:{
 component:CfCustomFeature4,
path:"/CfCustomFeature4"},
CfCustomFeature10:{
 component:CfCustomFeature10,
path:"/CfCustomFeature10"},
CfCustomFeature17:{
 component:CfCustomFeature17,
path:"/CfCustomFeature17"},
CfCustomFeature6:{
 component:CfCustomFeature6,
path:"/CfCustomFeature6"},
CfCustomFeature15:{
 component:CfCustomFeature15,
path:"/CfCustomFeature15"},
CfCustomFeature13:{
 component:CfCustomFeature13,
path:"/CfCustomFeature13"},
CfCustomFeature3:{
 component:CfCustomFeature3,
path:"/CfCustomFeature3"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
CfCustomFeature8:{
 component:CfCustomFeature8,
path:"/CfCustomFeature8"},
CfCustomFeature9:{
 component:CfCustomFeature9,
path:"/CfCustomFeature9"},
CfCustomFeature1:{
 component:CfCustomFeature1,
path:"/CfCustomFeature1"},
CfCustomFeature7:{
 component:CfCustomFeature7,
path:"/CfCustomFeature7"},
CfCustomFeature12:{
 component:CfCustomFeature12,
path:"/CfCustomFeature12"},
CfCustomFeature5:{
 component:CfCustomFeature5,
path:"/CfCustomFeature5"},
CfCustomFeature16:{
 component:CfCustomFeature16,
path:"/CfCustomFeature16"},
CfCustomFeature14:{
 component:CfCustomFeature14,
path:"/CfCustomFeature14"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;